import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { PieChartComponent } from './pie-chart.component';
import { NgxEchartsModule } from 'ngx-echarts';

@NgModule({
  declarations: [PieChartComponent],
  imports: [
    CommonModule,
    IonicModule,
    NgxEchartsModule.forRoot({
      /**
       * This will import all modules from echarts.
       * If you only need custom modules,
       * please refer to [Custom Build] section.
       */
      echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
    })
  ],
  exports: [PieChartComponent]
})
export class PieChartModule {}
