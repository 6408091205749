import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartComponent implements OnInit {
  // Variables
  @Input() options;
  @Output() chartSelect = new EventEmitter<any>();
  @Output() filterReset = new EventEmitter<any>();
  user: any = {};

  constructor() { }

  // Function to emit chart event when segment is clicked
  onChartClick(x: any) {
    // Emits the data to the parent page
    this.chartSelect.emit(x.data);
  }

  // Function to reset the filtering of jobs lists
  resetData() {
    // Emits the reset event to the parent when image is clicked
    this.filterReset.emit();
  }

  // Get's user data on init
  ngOnInit() {
    this.user = JSON.parse(window.localStorage.getItem('userService'));
  }

}
