import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';  
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { JobComponent } from './job.component';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { ToastService } from '../../services/toast.service';
import { userService } from '../../services/user.service';

@NgModule({
  imports: [ FormsModule, IonicModule, FontAwesomeModule, CommonModule],
  declarations: [JobComponent],
  exports: [JobComponent]
})
export class JobComponentModule {

  constructor(
    public userService: userService, 
    private router: Router, 
    private toastService: ToastService,
    private http: HttpClient
  ) {}
}
