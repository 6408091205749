import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-order-card',
  templateUrl: './order-card.component.html',
  styleUrls: ['./order-card.component.scss'],
})
export class OrderCardComponent implements OnInit {
  @Input() order;
  @Output() selectAOrder = new EventEmitter<any>();
  selectedOrder: any = {};
  orderColor: any;
  user: any = {};

  constructor() { }

  selectOrder() {
    this.selectAOrder.emit();
  }

  ngOnInit() {
    this.user = JSON.parse(window.localStorage.getItem('userService'));
  }

}
